import Assistant from "@curity/oauth-assistant";
import { withPrefix } from "gatsby"

const settings: Assistant.Settings = {
  clock_tolerance: 3000,
  disable_session_management: false,
  ignore_expiration: false,
  ignore_not_before: false,
  openid_configuration_url: `${process.env.GATSBY_DEV_PORTAL_LOGIN_URL}/oauth/v2/oauth-anonymous/.well-known/openid-configuration`,
  base_url: `${process.env.GATSBY_DEV_PORTAL_LOGIN_URL}`,
  client_id: "dev-portal",
  issuer: `${process.env.GATSBY_DEV_PORTAL_LOGIN_URL}/oauth/v2/oauth-anonymous`,
  redirect_uri: getRedirectOrigin() + withPrefix("/auth.html"),
  for_origin: getRedirectOrigin(),
  flow_type: "code",
  iframe: {
    targetElement: "body",
    width: null, // take default value
    height: null, // take default value
    style: null,
    closeButton: {
      visible: true,
      style: null,
      button: null,
      class: null
    },
    backdrop: {
        visible: true, // default is true
        style: null, // take default value
        backdropClass: "backdrop-class"
    }
  },
  allowed_origins: [getRedirectOrigin()], // default is [window.origin]
  check_session_iframe: undefined,
  session_polling_interval: 5, // polling interval in seconds, default is 5
  allowed_jwt_algorithms: ["RS256"],
  jwt_sig_public_key: { // allowed formats are jwk | jwks_uri | pem | issuer | metadata_url | raw
      format: "issuer", // in case of issuer, the issuer value will be taken from jwt payload
      value: null
  },
  debug: false
};

function getRedirectOrigin() {
    return typeof window !== "undefined" ? window.origin : "";
}

let instance: Assistant;
async function init(): Promise<Assistant> {
    // Prevents auth init when rendered
    // at build time, or on a server.
    if (typeof window !== "undefined") {
        if (instance) {
            return instance;
        }
        instance = new Assistant({
            ...settings
        });

        try {
            await instance.init();
            return instance;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify(e))
        }
    }
    return instance;
}

async function login(): Promise<string | undefined> {
    try {
        if (!instance) {
            await init();
        }
        return await instance.authorizeHiddenFallbackToVisible({ scope: "openid" });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error occurred while trying to login", JSON.stringify(e));
        return undefined;
    }
}

async function getTokenWithoutLoginPrompt(): Promise<string | null> {
  if (!instance) {
    await init();
  }

  return instance.getToken();
}

async function getToken(): Promise<string | null | undefined> {
    if (!instance) {
        await init();
    }

    const expiresIn = instance.getExpiresIn();
    if (!expiresIn) {
        return await instance.authorizeHiddenFallbackToVisible({ scope: "openid" });
    }

    return Promise.resolve(instance.getToken());
}

async function logout(): Promise<void | undefined> {
    if (!instance) {
        await init();
    }

    const postRedirectUri = getRedirectOrigin() + withPrefix("/auth.html");
    return instance.logout(postRedirectUri, false);
}

export { init, login, getTokenWithoutLoginPrompt, getToken, logout }
