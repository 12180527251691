const React = require("react");
const { AuthProvider } = require("./src/components/authentication/AuthContext");
const { BaseLayout } = require("@poppulo/gatsby-theme-poppulo-dev-portal");
import "./src/assets/css/prismTheme.css";
import "./src/assets/css/gatsby-remark-autolink-headers-override.css";
import "@fontsource/fira-sans/400.css";
import "@fontsource/fira-sans/500.css";

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
        {element}
    </AuthProvider>
  )
};

export const wrapPageElement = ({ element }) => {
  return (
    <BaseLayout>
      { element }
    </BaseLayout>
  )
};
