import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useState } from "react";
import { getTokenWithoutLoginPrompt, getToken, login, logout } from "./AuthManager";

export interface AuthContextType {
  userToken: string | undefined;
  isLoggedIn: () => boolean;
  refreshToken: () => Promise<string | null | undefined>;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  userToken: undefined,
  refreshToken: async () => undefined,
  isLoggedIn: () => false,
  login: () => { /* do nothing */ },
  logout: () => { /* do nothing */ }
});

const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [userToken, setUserToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    getTokenWithoutLoginPrompt().then(token => {
      setUserToken(token ? token : undefined);
    });
  },[]);

  const tryRefreshToken = async () => {
    const token = await getToken();
    setUserToken(token ? token : undefined);
    return token;
  };

  const tryLogin = async () => {
    await login();
    const token = await getToken();
    setUserToken(token ? token : undefined);
  };

  const tryLogout = async () => {
    await logout();
    setUserToken(undefined);
  };

  const isLoggedIn = () => {
    return userToken !== undefined;
  };

  return (
    <AuthContext.Provider value={{
      isLoggedIn: isLoggedIn,
      userToken: userToken,
      refreshToken: tryRefreshToken,
      login: tryLogin,
      logout: tryLogout
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };

export default AuthContext;
